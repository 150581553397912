<div class="container bluzap_maincontainer">
    <div class="text-center">
        <h1 class="product-title"><img style="height: 100px;width: 100px;" src="../../assets/images/Blu Zap.png">BLU ZAP</h1>
    </div>
    <div class="row">
        <div class="col-md-8">
          <div>
            <p class="bluzap_start">
              Revolutionizing Web Application Security
            </p>
          </div>
            
            <div class="text">
                In today’s digital landscape, web application security is paramount. As businesses shift to cloudnative
                solutions, ensuring robust security across all phases of development becomes essential. BLU ZAP is a
                powerful, multiplatform web application security testing tool that integrates seamlessly into your cloud
                infrastructure to provide cuttingedge security scanning. Designed for both novice developers and
                security professionals, BLU ZAP offers a dynamic and efficient way to identify vulnerabilities, automate
                security checks, and enhance your overall cloud security posture.
            </div>
        </div>
        <div class="col-md-4" style="text-align: end ">
            <!-- Need to add a sample image -->
            <img style="  height: 350px;width: 400px" src="../../assets/images/Blu Zap.png">
     </div>
    </div>
    <div class="sub-title_built">
        <p>
            Why We Built BLU ZAP
        </p>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div  style="width: 18rem;height: 27.3rem;margin-top: 5%;">
        <img style="height: 100px;width: 100px; align-items:center;" src="../../assets/images/Blu Zap.png">        <div class="card-body">
          <!-- <h5 class="card-title">Card title</h5> -->
          <p class="card-text">Our mission is to empower organizations by making 
            highend security testing tools accessible to teams of all sizes, 
            allowing them to maintain compliance and secure 
            their applications without disrupting their development cycles. </p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
        <div  style="width: 18rem;margin-top: 5%;">
    <img style="height: 100px;width: 100px; align-items:center;" src="../../assets/images/Blu Zap.png">        <div class="card-body">
      <!-- <h5 class="card-title">Card title</h5> -->
      <p class="card-text">From development through to deployment, BLU ZAP 
        covers the entire security lifecycle of your web applications.
         With Static Application Security Testing (SAST) and Dynamic
          Application Security Testing (DAST) capabilities, it provides a 
          comprehensive solution to identify vulnerabilities
         both in your application’s source code and in its running environment. </p>
    </div>
  </div>
</div>
<div class="col-md-3">
    <div  style="width: 18rem;height: 27.3rem;margin-top: 5%;">
<img style="height: 100px;width: 100px; align-items:center;" src="../../assets/images/Blu Zap.png">        <div class="card-body">
  <!-- <h5 class="card-title">Card title</h5> -->
  <p class="card-text">SAST helps identify vulnerabilities in the source code, 
    allowing development
     teams to catch flaws early, significantly reducing remediation costs. </p>
</div>
</div>
</div>
<div class="col-md-3">
    <div style="width: 18rem;height: 27.3rem;margin-top: 5%;">
<img style="height: 100px;width: 100px; align-items:center;" src="../../assets/images/Blu Zap.png">        <div class="card-body">
  <!-- <h5 class="card-title">Card title</h5> -->
  <p class="card-text"> DAST simulates realworld attacks on live applications, identifying vulnerabilities such as injection flaws, crosssite scripting (XSS), and more.
</p>
</div>
</div>
</div>
    </div>
   
</div>