import { Component } from '@angular/core';

@Component({
  selector: 'app-bluzap',
  templateUrl: './bluzap.component.html',
  styleUrls: ['./bluzap.component.css']
})
export class BluzapComponent {

}
