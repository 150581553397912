import { Component } from '@angular/core';

@Component({
  selector: 'app-opticos',
  templateUrl: './opticos.component.html',
  styleUrls: ['./opticos.component.css']
})
export class OpticosComponent {

}
