import { Component } from '@angular/core';

@Component({
  selector: 'app-swift-mi',
  templateUrl: './swift-mi.component.html',
  styleUrls: ['./swift-mi.component.css']
})
export class SwiftMiComponent {

}
