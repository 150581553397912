import { Component } from '@angular/core';

@Component({
  selector: 'app-ruppels',
  templateUrl: './ruppels.component.html',
  styleUrls: ['./ruppels.component.css']
})
export class RuppelsComponent {

}
