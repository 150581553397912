<div class="container">
    <div class="about-us">
        <h1 class="about-us-header">Welcome to Sivan Info Tech LLP</h1>
</div>

<div class="row">
    <div class="col-md-6 about-data-card-one" >
        <div class="about-data-card" >
            <!-- <div class="who-are-we-header">sivan infotech</div> -->
            <p class="who-are-we">At Sivan Info Tech, we are a premier innovator in cloud technology, 
                transforming businesses through tailored cloud solutions and managed services. Founded as 
                a forward-looking Limited Liability Partnership (LLP) and recognized by the Ministry of
             Corporate Affairs, we’ve made it our mission to drive digital transformation 
            and accelerate growth for organizations across industries. LLP Identification Number: ACA-1823</p>
        </div>
    </div>
    <div class="col-md-6 data1 ">
        <div class="about-data-card" >
            <!-- <div class="who-are-we-header">sivan infotech</div> -->
            <p class="who-are-we">Our expertise lies in seamlessly integrating cloudbased solutions 
                with business objectives, offering executive leadership teams the tools and insights needed 
                to navigate an increasingly complex technological landscape. From cloud accelerators to 
                comprehensive managed services, Sivan Info Tech is a trusted partner for organizations seeking to harness the full potential of the cloud.</p>
        </div>
    </div>
</div>
       
<div>
        <div class="title">
            <h1> What We Do</h1>
        </div>

    </div>
    
    <div class="col-md-12">
        <ul>

            <li>
                <h3> Cloud Governance & Managed Services:</h3>
                <p>Our comprehensive cloud managed services allow businesses to focus on strategic growth,
                     while we ensure that their cloud infrastructure operates securely, efficiently, and costeffectively. As your partner, we deliver:</p>
            </li>
            <li>
                <h3>Operational Excellence:</h3>
                <p>We provide enterprisegrade cloud governance,
                 ensuring operational continuity while driving cost efficiency and reducing overhead.</p>
            </li>
            <li>
                <h3> ailored Cloud Management:</h3>
                <p>We manage your cloud applications across leading Cloud Service Providers (CSPs), 
                    ensuring that your infrastructure remains optimized and aligned with your strategic goals.</p>
            </li>
            <li>
                <h3> Security & Compliance Leadership: </h3>
                <p>We implement advanced security solutions and governance frameworks, 
                enabling organizations to meet industry regulations and remain secure in an evolving threat landscape.</p>
            </li>
            
        </ul>
    </div>


   
    <div class="cloudacc">
        <div>
            <h1 class="h1-text">
                Cloud Accelerators  
            </h1>
            <p style="margin-top: 6vmin;margin-bottom: 3rem;text-align: justify;">
                Innovation is at the heart of Sivan Info Tech. We’ve developed an advanced suite of cloud 
                accelerators designed to streamline cloud operations and provide meaningful business impact 
                to your leadership teams. Our accelerators enable realtime insights, cost optimization, and automation to keep your cloud infrastructure ahead of the curve.
            </p>

        </div>
    </div>
    

<div class="conatiner">
    <div class="row acc_boxes">
        <div class="col-md-9">
            A FinOps Management Tool that provides Csuite executives with unparalleled visibility into
             cloud expenditure. Designed to align with the financial goals of the business, OptiCos helps executive teams manage 
          cloud costs effectively while driving performance and delivering maximum return on investment.
          </div>
          <div class="col-md-3 acc-img">
            <img class="acc-images" src="../../assets/images/Opticos1.png">
         </div>

    </div>
    <div class="row acc_boxes" style="margin-top: 4px;">
        <div class="col-md-3 acc-img">
            <img class="acc-images" src="../../assets/images/Blu_Zap1.png">
          </div>
          <div class="col-md-9">
            A Web Application Penetration Testing Tool purposebuilt for cloud environments. BLU ZAP empowers your 
            security and technology leaders with robust SAST (Static Application Security Testing) 
            and DAST (Dynamic Application Security Testing) capabilities, ensuring 
            that your applications remain secure and compliant, safeguarding your enterprise’s most valuable assets.
         </div>
        </div>
        <div class="row acc_boxes" style="margin-top: 4px;">
            <div class="col-md-9 ">
                An Executive Management Dashboard providing Csuite decisionmakers with a holistic, realtime 
                view of cloud projects and operational metrics. Ruppel’s VU enables executive leadership 
                to monitor performance, track progress, and make informed decisions based on comprehensive data insights.
                </div>
              <div class="col-md-3 acc-img">
                <img class="acc-images" src="../../assets/images/Ruppeuls1_vu.png">
             </div>
    
        </div>
        <div class="row acc_boxes" style="margin-top: 4px;">
            <div class="col-md-3 acc-img ">
                <img class="acc-images" src="../../assets/images/Terragen1_2.png">

              </div>
              <div class="col-md-9">
                A powerful tool that converts existing cloud infrastructure into Infrastructure as Code (IaC)
                , allowing technology leaders to automate cloud environments with greater precision and 
                control. TerraGen2 enables businesses to scale with confidence, reducing deployment times 
                and ensuring that infrastructure remains adaptable and resilient.

             </div>
    
        </div>

        
        <div class="row acc_boxes" style="margin-top: 4px;">
            <div class="col-md-9 ">
                A Cloud Migration Accelerator designed to help businesses seamlessly transition workloads 
                from onpremises to cloud or between cloud platforms. Swift MI ensures that executive teams can pursue strategic 
                migration initiatives with minimal disruption, maximizing uptime and delivering operational continuity.
              </div>
              <div class="col-md-3 acc-img">
               
                <img class="acc-images" src="../../assets/images/Swift1_MI.png">
            </div>
    </div>
</div>
   


    

    <div class="cloudacc">
        <div>
            <h1 class="h1-text" style="margin-top: 15vh;">
               Why Sivan Info Tech? 
            </h1>
            <p style="margin-top: 7vmin;margin-bottom: 3rem;">
                At Sivan Info Tech, we understand that executive management teams require more than just IT services; 
                they need strategic partnerships that drive measurable outcomes. We align our cloud solutions with 
                your business’s longterm goals, ensuring that every initiative supports your vision for growth,
                 innovation, and efficiency.
            </p>

        </div>
    </div>

    <!-- ////// 5 boxes ///////////    -->
    <div class="cloudacc">
        <div>
            <h1 class="h1-text" style="margin-top: 4rem ;margin-bottom: 3rem;">
                Key Value Propositions for the C-Suite
            </h1>
        </div>
    </div>
    <div class="conatiner" >
        <div class="row acc_boxes1">
            <div class="col-md-12" >
                <h3 style="text-align: center;margin-top: 7vh;"> Strategic Business Alignment</h3>
             </div>
            <div class="col-md-12">
                Our cloud solutions are designed to align with the core objectives of your organization, 
                whether that’s optimizing operational efficiency, improving financial outcomes, or enhancing customer experiences.
                 We offer strategic value that enhances business agility and competitive advantage.
              </div>
              
    
        </div>
        <div class="row acc_boxes1" style="margin-top: 4px;">
            <div class="col-md-12">
                 <h3 style="text-align: center;margin-top: 7vh;"> Cost Efficiency and Financial Control</h3>              
              </div>
              <div class="col-md-12">
                With tools like OptiCos, we give your financial leadership real-time insights into cloud spending, 
                ensuring that every dollar spent on cloud services delivers maximum value. We 
                focus on proactive cost governance to help your organization stay on budget and avoid wasteful spending.
             </div>
            </div>
            <div class="row acc_boxes1" style="margin-top: 4px;">
                <div class="col-md-12">
                    <h3 style="text-align: center;margin-top: 7vh;">Risk Management & Compliance</h3>
                 </div>
                <div class="col-md-12">
                    In an environment where security is paramount, our solutions ensure that your cloud infrastructure
                     remains secure, compliant, and resilient against evolving risks. With BLU ZAP and our managed services, we help security 
                    and compliance teams mitigate risks while meeting the regulatory demands of the modern enterprise.
                    </div>
                  
        
            </div>
            <div class="row acc_boxes1" style="margin-top: 4px;">
                <div class="col-md-12">
                   <h3 style="text-align: center;margin-top: 7vh;">Executive Level Visibility</h3>    
                  </div>
                  <div class="col-md-12">
                    Through advanced tools like Ruppel’s VU, we provide Csuite leaders with a transparent view of 
                    cloud operations, enabling better decisionmaking and more accurate forecasting. From project tracking to 
                    performance insights, our solutions help you lead with confidence and make informed, datadriven decisions.
                 </div>
        
            </div>
            <div class="row acc_boxes1" style="margin-top: 4px;" >
                <div class="col-md-12">
                    <h3 style="text-align: center;margin-top: 7vh;"> Futureproof Infrastructure</h3>
                    </div>
                <div class="col-md-12">
                    We help organizations stay ahead of the technological curve by automating and modernizing cloud 
                    infrastructure. Our solutions, such as TerraGen2 and Swift MI, enable technology leaders to 
                    rapidly adapt to changing business needs, ensuring agility and scalability across all cloud operations.
                  </div>
                 
        </div>
    </div>
   
   
<!-- //////////////////////////////  need to enable 5 boxes////////////////// -->
   
    <!-- <div class="row boxes" style="margin-top: 12vmin;">
        <div class="col-12 col-lg-4">
            <div class="card-bx gray-border ht-auto-mh mb-5">
<span class="box-heading"><h4 style="margin-bottom: 20px;">Strategic Business Alignment</h4></span>
<span class="partner-logo">
    <span>Our cloud solutions are designed to align with the core objectives of your organization, whether that’s optimizing operational efficiency, improving financial outcomes, or enhancing customer experiences.
         We offer strategic value that enhances business agility and competitive advantage.</span>
</span>
            </div>

        </div>
        <div class="col-12 col-lg-4">
            <div class="card-bx gray-border ht-auto-mh mb-5">
<span class="box-heading"><h4 style="margin-bottom: 20px;">Cost Efficiency & Financial Control</h4></span>
<span class="partner-logo">
    <span>With tools like OptiCos, we give your financial leadership real-time insights into cloud spending,
         ensuring that every dollar spent on cloud services delivers maximum value. We focus on proactive
          cost governance to help your organization stay on budget and avoid wasteful spending.</span>
</span>
            </div>

        </div>
        <div class="col-12 col-lg-4">
            <div class="card-bx gray-border ht-auto-mh mb-5">
<span class="box-heading"><h4 style="margin-bottom: 20px;">Risk Management and Compliance</h4></span>
<span class="partner-logo">
    <span>In an environment where security is paramount, our solutions ensure that your cloud infrastructure 
        remains secure, compliant, and resilient against evolving risks. With BLU ZAP and our managed services, we help security 
        and compliance teams mitigate risks while meeting the regulatory demands of the modern enterprise.</span>
</span>
            </div>

        </div>
        <div class="col-12 col-lg-4">
            <div class="card-bx gray-border ht-auto-mh mb-5">
<span class="box-heading"><h4 style="margin-bottom: 20px;">Executive Level Visibility</h4></span>
<span class="partner-logo">
    <span>Through advanced tools like Ruppel’s VU, we provide Csuite leaders with a transparent view of 
        cloud operations, enabling better decisionmaking and more accurate forecasting. From project tracking to performance insights,
         our solutions help you lead with confidence and make informed, datadriven decisions.</span>
</span>
            </div>

        </div>
        <div class="col-12 col-lg-4">
            <div class="card-bx gray-border ht-auto-mh mb-5">
<span class="box-heading"><h4 style="margin-bottom: 20px;">Futureproof Infrastructure</h4></span>
<span class="partner-logo">
    <span>Our cloud solutions are designed to align with the core objectives of your organization, whether that’s optimizing operational efficiency, improving financial outcomes, or enhancing customer experiences.
         We offer strategic value that enhances business agility and competitive advantage.</span>
</span>
            </div>

        </div>

    </div> -->


    <!-- /////////////////////////////// Why Partner with Us?///////////////////////////////////// -->
    <div class="cloudacc">
        <div>
            <h1 class="h1-text" style="margin-top: 2rem;">
                Why Partner with Us? 
            </h1>
            <p style="margin-top: 7vmin;margin-bottom: 3rem;">
                Sivan Info Tech is more than just a service provider—we are your strategic partner in cloud
                 transformation. Our focus on innovation, customization, and results means that we deliver solutions tailored to your unique business needs. By staying ahead of market trends and constantly evolving our offerings, 
                we ensure that your business remains competitive in a rapidly changing digital landscape.
            </p>
            <h5><u>Our Commitment to Innovation:</u></h5>
            <p>
                At Sivan Info Tech, our commitment to continuous innovation drives everything we do. 
                We are dedicated to developing new cloud accelerators, tools, and solutions that not 
                only solve today’s challenges but also anticipate the needs of tomorrow. For executive 
                management teams seeking to lead their organizations into the future,
                 we provide the technological backbone necessary for sustained growth and innovation.
            </p>
            <h5><u>Leading the Cloud Journey:</u></h5>
            <p>
                Whether you are looking to optimize cloud costs, enhance security, or modernize your infrastructure, 
                Sivan Info Tech LLP is here to help you navigate every step of your cloud journey. Our worldclass team and suite of cutting-edge tools 
                empower C suite executives to make strategic decisions that propel their organizations forward.
            </p>
            <p>
                Let’s work together to create a future where technology drives business growth, innovation, and success.
            </p>


        </div>
    </div>



    <!-- ///////////////////////testimonials///////////////// -->


    <div class="row testimonials h-50 container-fluid justify-content-around ">
        <div class="my-card col-12 col-md-2">
            <img class="icon-image" alt="Image Description" src="../../assets/images/heIcon.png">
            <div class="teste-name">Manikanta</div>
            <div class="teste-content">I joined this institute to take training for AWS Solution Architect – professional level. My all over experience is very good. The knowledge given by the trainers are very helpful to me & during training they solve thought related the topic. Thank you.</div>
        </div>
        <div class="my-card col-12 col-md-2">
            <img class="icon-image" alt="Image Description" src="../../assets/images/heIcon.png">
            <div class="teste-name">Vignesh</div>
            <div class="teste-content">I joined this institute to take training for AWS Solution Architect – professional level. My all over experience is very good. The knowledge given by the trainers are very helpful to me & during training they solve thought related the topic. Thank you.</div>
        </div>
        <div class="my-card col-12 col-md-2">
            <img class="icon-image" alt="Image Description" src="../../assets/images/sheIcon.png">
            <div class="teste-name">Indrani</div>
            <div class="teste-content">Provided in depth knowledge about the topics. Shown most of the topics practically which help in visualizing the topic. Providing training video is a plus.</div>
        </div>
        <div class="my-card col-12 col-md-2">
            <img class="icon-image" alt="Image Description" src="../../assets/images/heIcon.png">
            <div class="teste-name">Praveen</div>
            <div class="teste-content">Training was excellent with good interaction. Knowledge sharing is good. Recording facility is excellent for revising. Course was practically and informative. Trainer Kavitha is enthusiastic and aware of what she is explaining. The course helped to build confidence, Valuable experiences, and learning. She mapped all info’s to real-time world, where it’s helped a lot for me to understand easily.</div>
        </div>
    </div>


    <div style="margin-top: 10px;">
        <marquee class="marq" width="100%" scrollamount="10" direction="left" height="50px">
            <h1>Contact us today to learn how we can unlock the full potential of your cloud infrastructure.</h1>
        </marquee>
    </div>

<!-- /////////////////////////// social media////////////////////// -->
<section class="social-media">
        <div class="conatiner text-center">
          <p class="paragraph">FIND US ON SOCIAL MEDIA</p>
          <div class="social-icons">
            <a href="https://web.whatsapp.com/"><img src="../../assets/images/whatsapp-icon.png" alt=""></a>
            <a href="https://www.facebook.com/"><img src="../../assets/images/facebook-icon.png"alt=""></a>
            <a href=" https://www.instagram.com/sivan_info_tech/"><img src="../../assets/images/instagram-icon.png" alt=""></a>
            <!-- <a href=""><img src="../../assets/images/-icon.png" alt=""></a> -->
            <a href="https://www.linkedin.com/"><img src="../../assets/images/linkedin-icon.png"alt=""></a>
            <a href="https://x.com/"><img src="../../assets/images/twitter-icon.png" alt=""></a>
          </div>
        </div>
      </section>
      





    
   
    
    
    
    
    
    
    
   
   
        
    

    
       

   
    



<!-- <div class="image-container row">
    <div class="justify-content-between images row ">
        <div class="image col-md-6">
            <img class="styled-image" alt="Image Description"
                src="../../assets/images/about1-girl.png">
            <div class="image-description"><b>Kanagavalli N</b></div>
        </div>
        <div class="image  col-md-6">
            <img class="styled-image" alt="Image Description"
                src="../../assets/images/user_boy.png">
            <div class="image-description"><b>Gunashekaran G</b></div>
        </div>
        <div class="row">
            <div class="col-md-12" style="display: flex;
            justify-content: center;margin-top: 13px;">
                <div class="registered-info ">
                    <div>
                        <b> <i>LLP Identification Number - ACA-1823<br>
                                Pan of LLP - AEYFS 4555G<br>
                                Tan of LLP - CHES 69130F<br>
                                Email-info&#64;sitcloud.in<br>
                                Registered Address - 
                                No 9, Madhan Square,<br>
                                Guduvanchery, Chennai - 603202</i></b>
            
                    </div>
                </div>
               </div>

        </div>
        
    </div>
</div> -->

<!-- <div class="image-container row">  -->
   <!-- <div class="col-md-12" style="display: flex;justify-content: center;">
    <div class="registered-info col-md-4">
        <div style="margin-left: 95px;">
            <b> <i>LLP Identification Number - ACA-1823<br>
                    Pan of LLP - AEYFS 4555G<br>
                    Tan of LLP - CHES 69130F<br>
                    Email-info@sitcloud.in<br>
                    Registered Address - 
                    No 9, Madhan Square,<br>
                    Guduvanchery, Chennai - 603202</i></b>

        </div>
    </div>
   </div> -->
<!-- </div> -->

<!-- ==============  Cloud Accelerators ================ -->
    <!-- <section class="social-media">
        <div class="conatiner text-center">
          <p class="paragraph">Cloud Accelerators</p>
          <div class="social-icons">
            <div class="matnav">
                <div class="mat-list">
        <button class="btn bg-transaprent" routerLink="/cloudacclerator" style="
        box-shadow: 0px 0px 10px rgb(150, 150, 150);
        /* display: flex; */
        font-size: x-large;
        font-weight: 400;">Cloud Accelerators</button>
    </div>
            </div>
          </div>
        </div>
        
      </section> -->
      
      <!-- <div class="matnav>
        <mat-list>
      <mat-list-item>
        <button class="btn bg-transaprent" routerLink="/cloudacclerator">Cloud Accelerators</button>
      </mat-list-item>
    </mat-list>
    </div> -->
   
</div>