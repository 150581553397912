<div class="form-modal py-3">
    <div class="form-img-container">
        <!-- <img class='form-img' src="../../assets/images/gradimg-verify.png"> -->
        <img class='form-img' src="../../assets/images/cert123.jpeg">
    </div>
    <div class='form-content'>
        <div class="verifyform">
            <form [formGroup]="verifyform" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <div class="row col-lg-12 justify-content-between" style="align-items:center;">
                        <div class="col-md-5 title">
                            <label>Enrollment ID<sup>*</sup></label>
                        </div>
                        <div class="col-md-7">
                            <input type="text" formControlName="enrollmentID" class="form-control"
                                [ngClass]="{'is-invalid': submitted && f.enrollmentID.errors}" />
                            <div *ngIf="submitted && f.enrollmentID.errors" class="invalid-feedback">
                                <div *ngIf="f.enrollmentID.errors.required">Enrollment ID is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row col-lg-12 justify-content-between" style="align-items:center;">
                        <div class="col-md-5 title">
                            <label>Certificate ID<sup>*</sup></label>
                        </div>
                        <div class="col-md-7">
                            <input type="text" formControlName="certificateID" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.certificateID.errors }" />
                            <div *ngIf="submitted && f.certificateID.errors" class="invalid-feedback">
                                <div *ngIf="f.certificateID.errors.required">Certificate ID is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col text-center">
                        <button class="btn btn-primary" style="width:45%" id="align">Verify</button>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="verificationDetails" class="verificationdata">  
            <div [ngClass]="verificationDetails['status']==200 ? 'green': 'red'">{{verificationDetails['Message']}}</div>  
            <div class="details" *ngIf="verificationDetails['enrollmentDetails']">
                <div  class="field">
                    <label id="point" >Name: </label> 
                    <span>{{verificationDetails.userName}}</span>
                </div>
                <div  class="field">
                    <label id="point" >Course: </label> 
                    <span>{{verificationDetails['enrollmentDetails'].courseID}}</span>
                </div>
                <div  class="field">
                    <label id="point" >CertifiedOn: </label> 
                    <span class="value">{{verificationDetails['enrollmentDetails'].certifiedOn}}</span>
                </div>
                <div  class="field">
                    <label id="point" >Course Title: </label> 
                    <span>{{verificationDetails['enrollmentDetails'].courseTitle}}</span>
                </div>
                
            </div>
        </div>
    </div>
</div>