import { Component } from '@angular/core';

@Component({
  selector: 'app-terrgen2',
  templateUrl: './terrgen2.component.html',
  styleUrls: ['./terrgen2.component.css']
})
export class Terrgen2Component {

}
