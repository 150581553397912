<!-- 
<!doctype html>
<html lang="en">

<head>
    Required meta tags
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">

    Font Awesome Icons 
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
        integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=="
        crossorigin="anonymous" />

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet">

    <title>Forgot Password </title>
</head>

<body class="forgot_container">
    <div class="card">
        <p class="lock-icon">Forgot Password</p>
      
        <p class="word">You can reset your Password here</p>
        <input type="text" class="passInput" placeholder="Email address">
        <button class="forgot_btn">Send My Password</button>
    </div>
</body>

</html> -->



<div class="container main-container">
    <mat-card class="forgot_card">
    <div class="row" style="align-items: center;">
        <div class="col-md-6">
            <img class="forgot_image" src="../../assets/images/forgotimage.avif">

        </div>
        <div class="col-md-6">
            <div class="forgot_block">
                <p class="lock-icon" >Forgot Password</p>
      
                <p class="word">You can reset your Password here</p>
                <input matInput type="text" class="passInput" placeholder="Email address"><br>
                
                <button mat-raised-button class="forgot_btn" >Send My Password</button>
           <div>
         </div>

            </div>

        </div>

    </div>
</mat-card>
</div>
